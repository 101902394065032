exports.components = {
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-create-token-js": () => import("./../../../src/pages/create-token.js" /* webpackChunkName: "component---src-pages-create-token-js" */),
  "component---src-pages-denied-js": () => import("./../../../src/pages/denied.js" /* webpackChunkName: "component---src-pages-denied-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-apps-js": () => import("./../../../src/pages/my-apps.js" /* webpackChunkName: "component---src-pages-my-apps-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

